import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import PageHeader from "../../../components/page-header"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"
import QROverlay from "../../../components/qr-overlay"
import ImageTeaser from "../../../components/image-teaser"

const imgTL = require("../../../images/tidslinjen/dp-tidslinjen-teaser.jpg")

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const TimeLineStart = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Tidslinjen Översikt| Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/tidslinjen-oversikt"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/tidslinjen-oversikt"
        ></meta>
        <meta property="og:title" content="Tidslinjen Översikt"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--historiska-perspektiv"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Tidslinjen"
          >
            <p>
              Tidslinjen visar exempel på beslut och händelser som påverkat
              demokratin i Sverige. Hur har demokratin förändrats under 100 år?
            </p>
            <p>
              Titta på tidslinjen och använd diskussionsfrågorna. Du kan dela
              tidslinjen med dina elever.
            </p>
          </Hero>
          <section className="c-content-section" aria-labelledby="tidslinjen">
            <SectionHeader id="tidslinjen" text="Tidslinjen" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <ImageTeaser
                      qr="yes"
                      modifier="big"
                      img={imgTL}
                      imgAlt=""
                      title="Tidslinjen"
                      to="tidslinjen"
                      linkButtonText="Öppna tidslinjen"
                      buttonText="Länk och QR-kod"
                      onClick={() => {
                        handleShowOverlay(
                          "Tidslinjen",
                          "Öppna med QR-kod eller skriv in länk:",
                          "bit.ly/tidslinje1"
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="c-content-section"
            aria-labelledby="diskussionsfragor"
          >
            <SectionHeader id="diskussionsfragor" text="Diskussionsfrågor" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--medium">
                    <div className="c-content-section__content__questions">
                      <ul>
                        <li>
                          <h3>
                            1. Vilket år kunde människor inte längre
                            ”omyndigförklaras” i domstol?
                          </h3>
                          <p>
                            Diskutera: Vad betyder ”omyndigförklara”? Vad fick
                            den här förändringen för betydelse för demokratin i
                            Sverige.{" "}
                          </p>
                        </li>
                        <li>
                          <h3>
                            2. När fick vi en religionsfrihetslag i Sverige?
                          </h3>
                          <p>
                            Diskutera: Vad betydde den? På vilket sätt hänger
                            religionsfrihet ihop med demokrati?
                          </p>
                        </li>
                        <li>
                          <h3>3. Vilket år blev rösträttsåldern 18 år?</h3>
                          <p>
                            Diskutera: Varför är den just 18 år tror ni? Tycker
                            ni den borde vara högre eller lägre? Vad finns för
                            argument för och emot en lägre rösträttsålder?
                          </p>
                        </li>
                        <li>
                          <h3>
                            4. När fick vuxna som var arbetslösa eller tog emot
                            socialbidrag rösträtt?
                          </h3>
                          <p>
                            Diskutera: Varför tror ni de inte hade rösträtt
                            tidigare?
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default TimeLineStart
